.trip-list {
  list-style: none;
  padding: 0;
}

.trip-container {
  padding: 20;
  margin: 20px auto; 
  background-color: #fff; /* Sets a white background */
  border-radius: 8px; /* Rounds the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
  max-width: 800px;
}

.trip-container h3 {
  margin-top: 0;
}

.trip-container button {
  margin-right: 8px;
}

