* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: Arial, sans-serif;
    margin: 0;
}

.navbar {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.menu a {
  color: white;
  padding: 0 15px;
  text-decoration: none;
}

.hero {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background-size: cover;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px;
  background-color: #0066cc;
  color: white;
  border: none;
  cursor: pointer;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
}
.App {
  font-family: Arial, sans-serif;
  color: #333;
}

.App-header {
  background-color: white;  /* White background */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1, .App-header a {
  color: #0073b1; /* LinkedIn blue for text */
  text-decoration: none;
}

.menu a {
  color: #0073b1; /* Ensuring menu links are also blue */
  padding: 0 15px;
  text-decoration: none;
  font-size: 16px;
}

.menu a:hover {
  text-decoration: underline;
}
.profile {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: white;
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.profile-location {
  font-size: 12px;
}
.profile h2 {
  color: #0073b1; /* LinkedIn blue */
  margin-top: 10px;
}

.profile p {
  margin-top: 5px;
  line-height: 1.6;
}

footer {
  background-color: #283e4a;
  color: white;
  padding: 10px;
  text-align: center;
}

.experience {
    margin-bottom: 20px;
    padding: 10px;
    border-left: 3px solid #0073b1; /* A blue border on the left */
    background-color: #f8f8f8;
}

.experience h3 {
    color: #333;
}

.experience .years {
    font-weight: bold;
    color: #666;
}

.experience p {
    margin-top: 5px;
}

.experience-section {
  padding: 20px;
}

.summit-experience {
  border-bottom: 1px solid #ccc;
    background-color: #fff; /* Ensure background color for better visibility of shadow */
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15); /*
}

.summit-experience h3 {
  color: #0073b1; /* For example, a nice blue */
  margin: 0 0 10px 0;
}

.summit-experience p {
  margin: 5px 0;
}

.summit-experience a {
  color: #0073b1;
  text-decoration: none;
}

.summit-experience a:hover {
  text-decoration: underline;
}
form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 20px auto;
}

form h2 {
    text-align: center;
}

form div {
    margin-bottom: 10px;
}

label {
    margin-bottom: 5px;
}

input {
    padding: 8px;
    font-size: 16px;
}

button {
    padding: 30px;
    background-color: #0073b1;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #005690;
}

.homepage {
    text-align: center;
    padding: 20px;
    height: 100vh; /* Set the height to fill the full viewport */
    background-image: url('../public/background.JPG'); /* Path to your image */
    background-size: cover; /* Cover the entire page */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
}

.header {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for contrast */
    text-align: center; /* Center-align the text */
}
.header h1 {
    color: white;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    line-height: 1.2;
    letter-spacing: 1px;
}

.features {
    text-align: center; /* Center-align the text if that fits your design */
    margin-top: 30px;
    padding: 10px; /* Add padding for better spacing */
    background-color: rgba(248, 248, 248, 0.1); /* Adjust opacity here */
}

.features ul {
    list-style: none;
    list-style-position: inside; /* Keeps the bullets inside the content area */
    padding-left: 20px; /* Reduces left padding for tighter bullet points */
    margin: 0; /* Removes default margin */
}

.features li {
/*    padding: 10px;*/
    padding: 1px 0;
    background-color: rgba(248, 248, 248, 0.1); /* Adjust opacity here */
    /*background-color: #f0f0f0;*/
    margin-bottom: 5px;
}

.features p {
    padding: 5px 0;
    margin-bottom: 2px;
    font-size: 16px; /* Adjust font size for readability */
    line-height: 1.6; /* Good line height for reading comfort */
}

.login-signup .button {
    margin: 10px;
    padding: 10px 20px;
    background-color: lightgray;
    text-decoration: none;
    color: black;
}

.button-primary {
    background-color: blue;
    color: white;
    padding: 10px 20px;
}

.welcome-back {
    margin-top: 20px;
}

.login-signup-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    width: 80%; /* Adjust width as necessary */
    max-width: 500px; /* Ensures the box doesn't get too wide on larger screens */
/*    background-color: #f8f8f8;  Light background for the box */
    background-color: rgba(248, 248, 248, 0.9); /* Adjust opacity here */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
}

.login-signup-content {
    text-align: center;
    padding: 20px;
}

.login-signup-content h2 {
    margin-bottom: 20px; /* Increase space below the header */
}

.login-signup-content p {
    margin-bottom: 30px; /* Increase space below the paragraph */
}

.button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button.button-primary {
    background-color: #0056b3;
}

.button:hover {
    opacity: 0.85;
}

@media (max-width: 768px) {
    .login-signup-box {
        width: 95%;
        padding: 10px;
    },
    .button {
        padding: 15px 10px; 
    }
    .homepage {
        background-image: url('../public/background.JPG'); /* Path to your image */
        background-size: cover;
        background-position: center;
    }
}
.header h1, .header p {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.7);  
}

.button {
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);  
}


.profile-section {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.image-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
}

input[type="text"], textarea, input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #003580;
}

.save-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
}

/* App.css */

.profile-container {
    max-width: 800px; /* Sets the maximum width of the container */
    margin: 20px auto; /* Centers the container horizontally with some margin from top and bottom */
    padding: 20px; /* Adds padding inside the container */
    background-color: #fff; /* Sets a white background */
    border-radius: 8px; /* Rounds the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
    display: flex; /* Uses flexbox for layout */
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Aligns children in the center horizontally */
    text-align: center; /* Centers text */
}

.profile-container img {
    width: 150px; /* Adjusts the width of the image */
    height: 150px; /* Adjusts the height of the image */
    border-radius: 50%; /* Makes the image round */
    border: 4px solid #eee; /* Adds a light gray border around the image */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Adds a stronger shadow for more depth */
    object-fit: cover; /* Ensures the image covers the area without distorting */
    margin-bottom: 20px; /* Adds space below the image */
}

.profile-container h1, .profile-container h2 {
    color: #333; /* Dark grey color for headings */
    font-size: 24px; /* Sets font size for headings */
    margin-top: 0; /* Removes top margin from headings */
    margin-bottom: 10px; /* Adds a small margin below headings */
}

.profile-container p {
    color: #666; /* Medium grey color for paragraph text */
    font-size: 16px; /* Sets the font size for paragraph text */
    line-height: 1.5; /* Increases the line height for better readability */
    max-width: 600px; /* Restricts paragraph width for optimal reading length */
}

.profile-container button {
    padding: 10px 20px; /* Adds padding inside the button */
    background-color: #007BFF; /* Bright blue background for buttons */
    color: white; /* White text within the button */
    border: none; /* Removes border */
    border-radius: 4px; /* Slightly rounded corners on the button */
    cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
    font-size: 16px; /* Sets font size for button text */
    margin-top: 15px; /* Adds margin above the button */
}

.profile-container button:hover {
    background-color: #0056b3; /* Darker blue on hover for button */
}

.profile-container form {
    width: 100%; /* Form takes full width of the container */
    display: flex; /* Uses flexbox for layout within the form */
    flex-direction: column; /* Stacks form elements vertically */
    align-items: center; /* Centers form elements horizontally */
}

.profile-container input[type="text"], .profile-container textarea {
    width: 90%; /* Makes text inputs and textareas take up most of the container width */
    padding: 8px; /* Adds padding inside text inputs and textareas */
    border: 1px solid #ccc; /* Adds a subtle border */
    border-radius: 4px; /* Rounds the corners */
    margin-bottom: 10px; /* Adds space below inputs and textareas */
}

.summits-panel {
    max-width: 800px; /* Sets the maximum width of the container */
    margin: 20px auto; /* Centers the container horizontally with some margin from top and bottom */
    padding: 20px; /* Adds padding inside the container */
    background-color: #fff; /* Sets a white background */
    border-radius: 8px; /* Rounds the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
    display: flex; /* Uses flexbox for layout */
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Aligns children in the center horizontally */
    text-align: center; /* Centers text */
}

.summits-panel h2 {
    color: #333;
}

.summits-panel table {
    width: 100%;
    border-collapse: collapse;
}

.summits-panel th, .summits-panel td {
    padding: 4px;
    border: 0px solid #ddd;
    text-align: left;
}

.summits-panel th {
    background-color: #eee;
}

.summits-panel td a {
    color: blue;
    text-decoration: none;
}

.summits-panel td a:hover {
    text-decoration: underline;
}

.summits-panel button {
    padding: 6px 12px;
    margin-right: 4px;
    background-color: #007bff;
    color: blue;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.summits-panel button:hover {
    background-color: #0056b3;
}

.summits-container {
    width: 100%;
    background-color: #f9f9f9;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.0);
}
/* CSS to remove borders from inputs and textareas */
.name-input, .bio-textarea {
    border: none;  /* Removes the border */
    outline: none; /* Removes the focus outline - consider accessibility implications */
}

/* Optional: Add more styles to improve the appearance */
.name-input, .bio-textarea {
    width: 100%;
    padding: 8px;
    background-color: #f4f4f4; /* Adds a light gray background */
    margin-bottom: 10px;
}

/* Style for buttons to keep a consistent look */
button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

button:hover {
    background-color: #45a049;
}

.grid-container {
    display: grid;
    grid-template-rows: auto auto;  
    gap: 20px;  
    padding: 20px;
}
.summit-container {
    max-width: 800px; /* Sets the maximum width of the container */
    margin: 20px auto; /* Centers the container horizontally with some margin from top and bottom */
    padding: 20px; /* Adds padding inside the container */
    background-color: #fff; /* Sets a white background */
    border-radius: 8px; /* Rounds the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
    display: flex; /* Uses flexbox for layout */
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Aligns children in the center horizontally */
    text-align: center; /* Centers text */
}

.summit-container img {
    width: 150px; /* Adjusts the width of the image */
    height: 150px; /* Adjusts the height of the image */
    border-radius: 50%; /* Makes the image round */
    border: 4px solid #eee; /* Adds a light gray border around the image */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Adds a stronger shadow for more depth */
    object-fit: cover; /* Ensures the image covers the area without distorting */
    margin-bottom: 20px; /* Adds space below the image */
}

.summit-container h1, .summit-container h2 {
    color: #333; /* Dark grey color for headings */
    font-size: 24px; /* Sets font size for headings */
    margin-top: 0; /* Removes top margin from headings */
    margin-bottom: 10px; /* Adds a small margin below headings */
}

.summit-container p {
    color: #666; /* Medium grey color for paragraph text */
    font-size: 16px; /* Sets the font size for paragraph text */
    line-height: 1.5; /* Increases the line height for better readability */
    max-width: 600px; /* Restricts paragraph width for optimal reading length */
}

.summit-container button {
    padding: 10px 20px; /* Adds padding inside the button */
    background-color: #007BFF; /* Bright blue background for buttons */
    color: white; /* White text within the button */
    border: none; /* Removes border */
    border-radius: 4px; /* Slightly rounded corners on the button */
    cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
    font-size: 16px; /* Sets font size for button text */
    margin-top: 15px; /* Adds margin above the button */
}

.skills-container {
    max-width: 800px; /* Sets the maximum width of the container */
    margin: 20px auto; /* Centers the container horizontally with some margin from top and bottom */
    padding: 20px; /* Adds padding inside the container */
    background-color: #fff; /* Sets a white background */
    border-radius: 8px; /* Rounds the corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow for depth */
    display: flex; /* Uses flexbox for layout */
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Aligns children in the center horizontally */
    text-align: center; /* Centers text */
}

.skill-bubble {
    padding: 5px 10px;
    margin: 5px;
    background-color: #0073e6; /* Change as needed */
    color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.skill-bubble button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

select {
    padding: 8px;
    margin-right: 5px;
}

button {
    padding: 8px 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 5px 0;
}

.timeline-container {
    width: 100%;
    overflow-x: auto;
    padding: 20px;
}

.timeline {
    position: relative;
    width: max-content; /* Ensure it stretches as needed */
    height: 4px;
    background: #D3D3D3;
    margin: 50px 0;
}

.timeline-line {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #377bff; /* Line color */
}

.marker {
    position: absolute;
    top: -8px; /* Adjust based on your design */
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 4px solid #377bff; /* Marker style */
    border-radius: 50%;
    transform: translateX(-50%);
}

input[type="text"], input[type="checkbox"] {
    width: 100%;
    padding: 4px;
    box-sizing: border-box; /* Ensures the input does not overflow its cell */
}

.error {
    color: red;
}

