.profile-item {
  max-width: 800px;
  margin: 20px auto; /* Centers the container horizontally with some margin from top and bottom */
  padding: 20px;
  background-color: white;
  border-radius: 8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  list-style-type: none; /* Ensure no bullet points */
 display: flex;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px; /* Adds space between the image and text */
}

.profile-info {
  flex-grow: 1;
}

