.top-menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.top-menu-bar .logo h1 {
  margin: 0;
  color: #0073b1;
}

.search-container input {
  width: 150px;
  padding: 8px;
  font-size: 14px;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  padding: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #0073b1;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

